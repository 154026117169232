var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-pagination", {
        attrs: {
          layout: "total, prev, pager, next, jumper",
          "page-size": _vm.pageSize,
          total: _vm.totalLists,
          "current-page": _vm.currentPage
        },
        on: { "current-change": _vm.currentChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }