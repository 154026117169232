<template>
  <div>
    <!-- 要有:page-size(每頁的筆數)和:total為總比數-->
    <!-- current-page為當前頁數 -->
    <el-pagination
      layout="total, prev, pager, next, jumper"
      :page-size="pageSize"
      :total="totalLists"
      :current-page="currentPage"
      @current-change="currentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    totalLists:{
      type: Number,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    propsCurrentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
  computed: {
    currentPage(){
      return this.propsCurrentPage
    }
    
  },
  methods: {
    currentChange(val) {
      console.log("current page",val);
      this.$emit("currentChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
